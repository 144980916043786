import { mapMutations, mapActions, mapState } from 'vuex'
import SButton from '../ui/s-button'
import { EmailValidator } from '../../plugins/helper'

export default {
  name: 'Register',

  props: {
    caption: {
      type: String,
      default: 'AUTH_SETUP_TITLE',
    },
  },

  components: {
    SButton,
  },

  data() {
    return {
      email: '',
    }
  },

  methods: {
    ...mapMutations(['setError']),
    ...mapActions(['sendResetPasswordInstructions']),

    async register() {
      if (EmailValidator(this.email)) {
        await this.sendResetPasswordInstructions(this.email)

        this.$emit('instructionsSent')
      } else {
        this.setError('INVALID_EMAIL')
      }

      this.email = ''
    },
  },

  computed: {
    ...mapState(['config', 'loading']),

    showLinkCollaboratorWithoutCorpEmail() {
      return this.$route.name === 'company-report'
    },
  },
}
